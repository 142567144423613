import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Analytics } from "@vercel/analytics/react";
import LogoImage from "../../images/logo/WebCCLVietnameseLogo.svg";
import { ReactComponent as FacebookIcon } from "images/004-facebook.svg";
import { ReactComponent as InstagramIcon } from "images/006-instagram.svg";
import { ReactComponent as TiktokIcon } from "images/012-tiktok.svg";
import { ReactComponent as YoutubeIcon } from "images/019-youtube.svg";

const Container = tw.div`relative bg-gray-900 text-gray-100 -mx-8 -mb-8 px-8`;
const Content = tw.div`max-w-screen-xl mx-auto pt-16 pb-8`;
const NoOfColumns = tw.div`flex flex-wrap justify-between`;

const Column = tw.div`w-full md:w-1/6 mb-8 md:mb-0 text-sm sm:text-base text-center md:text-left`;
const CompanyColumn = tw.div`text-center md:text-left mb-16 lg:mb-0 w-full lg:w-1/5`;

const ColumnHeading = tw.h5`font-bold uppercase`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-100 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center lg:justify-start`;
const LogoImg = tw.img`w-8 mr-2`;
const LogoText = tw.h5`text-xl font-black`;

const CompanyAddress = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto lg:mx-0 lg:mr-4 leading-loose text-center lg:text-left`;

const SocialLinksContainer = tw.div`mt-4 text-center lg:text-left`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-gray-500 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

const CopyrightAndCompanyInfoRow = tw.div`pb-0 text-sm font-normal flex flex-col sm:flex-row items-center justify-between`;
const CopyrightNotice = tw.div`flex items-center justify-center`;
const CompanyInfo = tw.div`justify-end`;

const Divider = tw.div`my-8 border-b-2 border-gray-800`;

// Get values from environment variables
const FOOTER_LOGO_TEXT = process.env.REACT_APP_FOOTER_LOGO_TEXT || "Home URL";
const FOOTER_ADDRESS = process.env.REACT_APP_FOOTER_ADDRESS || "Office Address";
const FACEBOOK_URL = process.env.REACT_APP_FACEBOOK_URL || "Facebook URL";
const YOUTUBE_URL = process.env.REACT_APP_YOUTUBE_URL || "Youtube URL";
const TIKTOK_URL = process.env.REACT_APP_TIKTOK_URL || "TikTok URL";
const INSTAGRAM_URL = process.env.REACT_APP_INSTAGRAM_URL || "Instagram URL";
const CONTACT_AU_PHONE = process.env.REACT_APP_CONTACT_AU_PHONE || "AUS Phone URL";
const CONTACT_AU_PHONE_LABEL = process.env.REACT_APP_CONTACT_AU_PHONE_LABEL || "AUS Phone";
const CONTACT_VN_PHONE = process.env.REACT_APP_CONTACT_VN_PHONE || "VNE Phone URL";
const CONTACT_VN_PHONE_LABEL = process.env.REACT_APP_CONTACT_VN_PHONE_LABEL || "VNE Phone";
const CONTACT_EMAIL = process.env.REACT_APP_CONTACT_EMAIL || "Business Email";
const COMPANY_NAME = process.env.REACT_APP_COMPANY_NAME || "Company Name";
const COPYRIGHT_YEAR = process.env.REACT_APP_COPYRIGHT_YEAR || "2023";
const COPYRIGHT_INFO = process.env.REACT_APP_COPYRIGHT_INFO || "Built with ❤️";

// Additional variables for menu links
const HOME_PAGE_MASTER_URL = process.env.REACT_APP_HOME_PAGE_MASTER_URL || "CCL Master Website URL";
const HOME_PAGE_PLATFORM_URL = process.env.REACT_APP_HOME_PAGE_PLATFORM_URL || "CCL Master Platform URL";
const ABOUT_US_URL = process.env.REACT_APP_ABOUT_US_URL || "CCL Master About Us URL";
const RESULTS_URL = process.env.REACT_APP_RESULTS_URL || "CCL Master Results URL";
const REVIEWS_URL = process.env.REACT_APP_REVIEWS_URL || "CCL Master FB-Reviews URL";
const ABR_URL = process.env.REACT_APP_ABR_URL || "ABR URL";
const ABR_TEXT = process.env.REACT_APP_ABR_TEXT || "ABR Details";

export default function DefaultFooter() {
  return (
    <Container>
      <Analytics />
      <Content>
        <NoOfColumns>
          <CompanyColumn>
            <LogoContainer>
              <LogoText>{FOOTER_LOGO_TEXT}</LogoText>
            </LogoContainer>
            <CompanyAddress>{FOOTER_ADDRESS}</CompanyAddress>
            <SocialLinksContainer>
              <SocialLink href={FACEBOOK_URL}>
                <FacebookIcon />
              </SocialLink>
              <SocialLink href={YOUTUBE_URL}>
                <YoutubeIcon />
              </SocialLink>
              <SocialLink href={TIKTOK_URL}>
                <TiktokIcon />
              </SocialLink>
              <SocialLink href={INSTAGRAM_URL}>
                <InstagramIcon />
              </SocialLink>
            </SocialLinksContainer>
          </CompanyColumn>
          <Column>
            <ColumnHeading>
              <Link href="#">Home Page</Link>
            </ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href={HOME_PAGE_MASTER_URL}>CCL Master</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href={HOME_PAGE_PLATFORM_URL}>
                  PLATFORM by CCL Master
                </Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>
              <Link href={ABOUT_US_URL}>About Us</Link>
            </ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href={RESULTS_URL}>* Kết quả của học viên</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href={REVIEWS_URL}>* Reviews của học viên</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href={ABR_URL}>{ABR_TEXT}</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Contact</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href={`tel:${CONTACT_AU_PHONE}`}>
                  AU: {CONTACT_AU_PHONE_LABEL}
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link href={`tel:${CONTACT_VN_PHONE}`}>
                  VN: {CONTACT_VN_PHONE_LABEL}
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link href={`mailto:${CONTACT_EMAIL}`}>
                  {CONTACT_EMAIL}
                </Link>
              </LinkListItem>
            </LinkList>
          </Column>
        </NoOfColumns>

        <Divider />

        <CopyrightAndCompanyInfoRow>
          <CopyrightNotice>
            <LogoImg src={LogoImage} alt="footer-logo" /> {COMPANY_NAME}
          </CopyrightNotice>
          <CompanyInfo>
            {COPYRIGHT_INFO} &copy; {COPYRIGHT_YEAR}
          </CompanyInfo>
        </CopyrightAndCompanyInfoRow>
      </Content>
    </Container>
  );
}